import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Stack, Grid, TextField} from '@mui/material'
import { StyledAutocomplete, StyledTextfield } from '../../components/styled-components/inputField'
import { StyledInfo } from '../../components/styled-components/button'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO, format, addDays } from 'date-fns';
import { StyledDatePicker } from '../../components/styled-components/inputField'
import { StyledButton } from '../../components/styled-components/button'
// import AccordianFaq from '../../components/faq-accordian/AccordianFaq';
import AxiosInstance from '../../config/AxiosInstance';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import Loading from '../../components/common-components/CircularLoading';
import QuoteComponent from '../../components/quote-component/QuoteComponent';
import MonthlyQuote from '../../components/quote-component/MonthlyQuote';

const libraries = ['places']; // Define the libraries array outside of the component

function RentOnline({label}) {
    
    //loading the google maps api key from environment file
    const api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

    // eslint-disable-next-line
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: api_key,
        libraries,
    })

    const payment_link = process.env.REACT_APP_PAYMENT_URL
    const key_name = process.env.REACT_APP_PAYMENT_API_KEY_NAME
    const key_value = process.env.REACT_APP_PAYMENT_API_KEY_VALUE

    const textFieldRef = useRef(null); // Create a ref for the text field

    const qData = JSON.parse(sessionStorage.getItem('quoteData')) || {};
    const reg_dat = JSON.parse(sessionStorage.getItem('registration_data')) || {}; // data enterd in screen 1
    const quote_details = JSON.parse(sessionStorage.getItem('quote_details')) || {}; // quote details from screen 2
    const rtpd = JSON.parse(sessionStorage.getItem('realtime_price_data')) || {}; // data enterd in screen 2
    const rondat = JSON.parse(sessionStorage.getItem('s3data')) || {}; // used to restore the data entered in screen 3 if the user goes back to previous page and comes back again
    const formated_phone = sessionStorage.getItem('formatted_phone_number'); // retrieves the formatted phone stored in screen 1
    const inActiveDates = JSON.parse(sessionStorage.getItem('bigboxinfo')).customer_appointments_closed.map(entry => entry.date.split(' ')[0]); // retrieving the inactive dates from the bigbox info stored in screen 1
    const valid_zips = JSON.parse(sessionStorage.getItem('bigboxinfo')).delivery_rates.map(entry => entry.zip); // gets the list of valid zips
    const calculationData = JSON.parse(sessionStorage.getItem('calcData')) || {}; // gets the data used for calculation in the previous step
    const dr = JSON.parse(sessionStorage.getItem('bigboxinfo')).delivery_rates.map(entry => entry); // gets the delivery rates
    const zc = sessionStorage.getItem('delcalzip'); // gets the value of zip code used for determining the delivery rate
    const effectExecutedRef = useRef(false); // keeps track of the useEffect execution
    const [isSubmitting, setIsSubmitting] = useState(false); 

    // function to find the delivery rate from the zip code
    const findAmountByZip = (zip) => {
        const deliveryRate = dr.find(deliveryrate => deliveryrate.zip === zip)
        return deliveryRate ? deliveryRate.amount : null
    }

    // function to set if the date is active or inactive in the date picker
    function isDateInActive(date, inActiveDates) {
        const formattedDate = format(date, 'yyyy-MM-dd');
        return inActiveDates.includes(formattedDate);
    }

    // value of the initial delivery date (set to one day after today)
    const initialDeliveryDate = addDays(new Date(), 1);

    // function to set the valid date for delivery or pickup
    // this function prevents the delivery or pickup dates to set to inactive dates accidently
    const getValidDate = (date, inActiveDates) => {
        let validDate = date;
        while (isDateInActive(validDate, inActiveDates)) {
            validDate = addDays(validDate, 1);
        }
        return validDate;
    }

    // sets valid delivery date by calling the getValidDate function and passing the initialDeliveryDate
    const validInitialDeliveryDate = getValidDate(initialDeliveryDate, inActiveDates);
    const initialPickupDate = addDays(validInitialDeliveryDate, 1); // sets the initial pickup date one day after the valid delivery date
    // sets valid pickup date by calling the getValidDate function and passing the initialPickupDate
    const validInitialPickupDate = getValidDate(initialPickupDate, inActiveDates);

    const [zipError, setZipError] = useState(""); // zip error state
    const [firstNameError, setFirstNameError] = useState(""); // first name error state
    const [lastNameError, setLastNameError] = useState(''); // last name error state
    const [phoneError, setPhoneError] = useState(''); // phone error state
    const [emailError, setEmailError] = useState(''); // email error state
    const [add1Error, setAdd1Error] = useState(''); // address line 1 error state
    const [cityError, setCityError] = useState(''); // city error state
    const [stateError, setStateError] = useState(''); // state error state
    // const [submitError, setSubmitError] = useState('');
    const [loading, setLoading] = useState(true); // loading state
    const [disabled, setDisabled] = useState(false); // disabled state
    const [resDat, setResDat] = useState({}); // response data state
    const [discount, setDiscount] = useState(""); // discount state
    const [firstName, setFirstName] = useState(rondat.first_name || (reg_dat.first_name || '')); // first name state
    // eslint-disable-next-line
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(formated_phone); // formatted phone number state
    const [lastName, setLastName] = useState(rondat.last_name || (reg_dat.last_name || '')); // last name state
    const [email, setEmail] = useState(rondat.email || (reg_dat.email || '')); // email state
    const [phoneNumber, setPhoneNumber] = useState(rondat.phone_number || (reg_dat.phone_number || '')); // phone number state
    const [deliveryDate, setDeliveryDate] = useState(rondat.delivery_date || format(validInitialDeliveryDate, 'yyyy-MM-dd')); // delivery date state
    const [deliveryTime, setDeliveryTime] = useState(rondat.delivery_time || "800"); // delivery time state
    const [pickupDate, setPickupDate] = useState(rondat.pickup_date || format(validInitialPickupDate, 'yyyy-MM-dd')); // pickup date state
    const [loca, setLoca] = useState(rondat.location || "warehouse"); // location state
    const [autocomplete, setAutocomplete] = useState(null);
    const [address, setAddress] = useState({
        address1: rondat.address1 || "",
        address2: rondat.address2 || "",
        city: rondat.city || "",
        state: rondat.state || "",
        zip_code: valid_zips.includes(rondat.f_zip_code) ? (rondat.f_zip_code || (reg_dat.zip_code || '')) : zc
    });// address object state
    const [sendData, setSendData] = useState(
        {
            lead_id: sessionStorage.getItem('lead_id') || 0,
            first_name: reg_dat.first_name || '',
            last_name: reg_dat.last_name || '',
            phone_number: reg_dat.phone_number || '',
            email: reg_dat.email || '',
            zip_code: reg_dat.zip_code || '',
            number_of_months_needed: rtpd.number_of_months_needed || '0',
            number_of_boxes: rtpd.number_of_boxes || '0',
            number_of_padlocks: rtpd.number_of_padlocks || '0',
            protection: rtpd.protection,
            promotion_code: rtpd.promotion_code || "",
            promotion_code_value: rtpd.promotion_code_value || 0,
            delivery_date: rondat.delivery_date || "",
            delivery_time: rondat.delivery_time || "",
            pickup_date: rondat.pickup_date || "",
            location: rondat.location || "",
            address1: rondat.address1 || "",
            address2: rondat.address2 || "",
            city: rondat.city || "",
            state: rondat.state || "",
            f_zip_code: rondat.f_zip_code || ""
        }
    ); // send Data object state

    const [s3data, setS3Data] = useState({
        first_name: rondat.first_name || '',
        last_name: rondat.last_name || '',
        phone_number: rondat.phone_number || "",
        email: rondat.email || "",
        delivery_date: rondat.delivery_date || "",
        delivery_time: rondat.delivery_time || "",
        pickup_date: rondat.pickup_date || "",
        location: rondat.location || "",
        address1: rondat.address1 || "",
        address2: rondat.address2 || "",
        city: rondat.city || "",
        state: rondat.state || "",
        f_zip_code: rondat.f_zip_code || ""
    }); // current page data state

    const [quoteData, setQuoteData] = useState({
        monthlyRent: null,
        monthlyProtectionPlan: null,
        lock: null,
        initialDeliveryFee: null,
        initialDeliveryDiscount: null,
        promodiscount: null,
        subtotal: null,
        tax: null,
        total: null,
        nofmon: null,
        nofbox: null,
        nofpad: null
    })
    const [delInst, setDelInst] = useState(sessionStorage.getItem("delInst") || "")

    useEffect(()=>{
        if (effectExecutedRef.current) return;
        effectExecutedRef.current = true;
        window.scrollTo(0, 0);
    })

    // useEffect to calculate the pricing whenever the value of zip code changs
    useEffect(() => {
        const getCalculation = async (df, calczc) => {
            const calcDat = {
                ...calculationData,
                delivery_fee: (Number(df).toFixed(2)).toString(),
                delivery_date: deliveryDate,
                zip_code: calczc
            }
            try {
                setLoading(true);
                const response = await AxiosInstance.post("/bigbox-calculate-cost", calcDat);
                setResDat(response.data.data)
                setDisabled(false)
            }
            catch (error) {
                setDisabled(true)
                toast.error('Error: We are unable to provide quote now. Please call us for a quote.')
            } finally {
                setLoading(false);
            }
        };
        // if the entered zip is valid then the calculation is done based on the entered zip code
        if (valid_zips.includes(address.zip_code)) {
            const deliveryFee =findAmountByZip(address.zip_code)
            setDiscount(calculationData.number_of_months_needed === "3" ? "99.00": "0.00")
            getCalculation(deliveryFee, address.zip_code);
        }
        // if the entered zip is invalid then the calculation is done based on the previously valid zip code
        else {
            const deliveryFee = findAmountByZip(zc)
            setDiscount(calculationData.number_of_months_needed === "3" ? "99.00": "0.00")
            getCalculation(deliveryFee, zc);
        }
        // eslint-disable-next-line
    }, [address.zip_code, calculationData.number_of_months_needed, deliveryDate])

    useEffect(()=>{
        if(!loading)
       { setQuoteData({
            monthlyRent: zipError?"0.00":((resDat.cost?.monthly).toFixed(2) || ''),
            monthlyProtectionPlan: zipError?"0.00":(Number(quote_details.mon_prot_plan * calculationData.number_of_boxes).toFixed(2)),
            lock: zipError?"0.00":quote_details.lock,
            initialDeliveryFee: zipError?"0.00":(Number(findAmountByZip(address.zip_code)).toFixed(2) || ""),
            initialDeliveryDiscount: zipError?"0.00":(Number(discount).toFixed(2)),
            promodiscount: zipError?"0.00":quote_details.promo_disc,
            subtotal: zipError?"0.00":(resDat.cost?.final_total.toFixed(2) -resDat.cost?.tax.toFixed(2) || ""),
            tax: zipError?"0.00":(Number(resDat.cost?.tax !== undefined && resDat.cost?.tax !== null ? resDat.cost.tax : "").toFixed(2)),
            total: zipError?"0.00":(resDat.cost?.final_total.toFixed(2) || ""),
            nofmon: rtpd.number_of_months_needed,
            nofbox: rtpd.number_of_boxes,
            nofpad: rtpd.number_of_padlocks
        })}
        // eslint-disable-next-line
    },[resDat, zipError])

    // function to display the phone number in (xxx) xxx-xxxx format to the user in the input field
    const formatPhoneNumber = (number) => {
        if (!number) return number;
        const phoneNumber = number.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    // useEffect to set the formatted phone number
    useEffect(() => {
        setFormattedPhoneNumber(formatPhoneNumber(phoneNumber));
    }, [phoneNumber]);

    // useEffect to set the states of this page data and the send data for the final post api call
    useEffect(() => {
        setSendData(prevDat => ({
            ...prevDat,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            email: email,
            delivery_date: deliveryDate,
            delivery_time: deliveryTime,
            pickup_date: pickupDate,
            location: loca,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            state: address.state,
            f_zip_code: address.zip_code
        }));
        setS3Data(prevDat => ({
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            email: email,
            delivery_date: deliveryDate,
            delivery_time: deliveryTime,
            pickup_date: pickupDate,
            location: loca,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            state: address.state,
            f_zip_code: address.zip_code
        }))
    }, [firstName, lastName, phoneNumber, email, deliveryDate, deliveryTime, pickupDate, loca, address.address1, address.address2, address.city, address.state, address.zip_code])
    
    useEffect(()=>{
        setZipError(valid_zips.includes(address.zip_code)?"":"Bigbox not available in this location")
        // eslint-disable-next-line
    },[address.zip_code])
    
    
    const navigate = useNavigate(); // function to navigate to the desired page of the app

    // function to return the dates to be disabled for the delivery date picker
    const shoulDisablePickupDate = (date) => {
        // eslint-disable-next-line
        const formattedDate = format(date, 'yyyy-MM-dd');
        const isBeforDelivery = date <= parseISO(deliveryDate);
        return isBeforDelivery || isDateInActive(date, inActiveDates);
    }
    // function to return the dates to be disabled for the pickup date picker
    const shouldDisableDeliveryDate = (date) => {
        const formattedToday = format(new Date(), 'yyyy-MM-dd');
        const formattedDate = format(date, 'yyyy-MM-dd');
        const isToday = formattedDate === formattedToday;
        return isToday || isDateInActive(date, inActiveDates);
    };

    // function to handle the back button click
    const handleBack = () => {
        // stores the data entered in this screen before navigating to previous page
        sessionStorage.setItem('s3data', JSON.stringify(s3data));
        sessionStorage.setItem('delcalzip', valid_zips.includes(address.zip_code) ? address.zip_code : zc);
        sessionStorage.setItem("delInst", delInst)
        navigate('/bigbox-quote')
    }

    const makePayment = async (dat,pid) => {

        const form = document.createElement('form');
        form.method = 'post';
        form.action = payment_link;

        const params = {
            'userid': '10001',
            'zip_code': reg_dat.zip_code,
            'delivery_date': dat.delivery_date,
            'number_of_boxes': dat.number_of_boxes,
            'number_of_months_needed': dat.number_of_months_needed,
            'delivery_time': dat.delivery_time,
            'storage_location': dat.location,
            'pickup_date': dat.pickup_date,
            'promotion_code': dat.promotion_code,
            'promotion_code_value': dat.promotion_code_value,
            'referral_source': '',
            'rental_rate': calculationData.rental_rate,
            'delivery_fee': (resDat.cost.delivery === 0 ? "0.00" : resDat.cost.delivery.toFixed(2)).toString(),
            'one_time_fee': (resDat.cost.oneTimeFee === 0 ? "0.00" : resDat.cost.oneTimeFee.toFixed(2)).toString(),
            'merchandise_fee': (resDat.cost.merchandise === 0 ? "0.00" : resDat.cost.merchandise.toFixed(2)).toString(),
            'tax': (resDat.cost.tax === 0 ? "0.00" : resDat.cost.tax.toFixed(2)).toString(),
            'total': (resDat.cost.final_total === 0 ? "0.00" : resDat.cost.final_total.toFixed(2)).toString(),
            'cost[tax]': (resDat.cost.tax === 0 ? "0.00" : resDat.cost.tax.toFixed(2)).toString(),
            'cost[total]': (resDat.cost.final_total === 0 ? "0.00" : (resDat.cost.final_total).toFixed(2)).toString(),
            'cost[monthly]': (resDat.cost.monthly === 0 ? "0.00" : resDat.cost.monthly.toFixed(2)).toString(),
            'cost[delivery]': (resDat.cost.delivery === 0 ? "0.00" : resDat.cost.delivery.toFixed(2)).toString(),
            'cost[oneTimeFee]': (resDat.cost.oneTimeFee === 0 ? "0.00" : resDat.cost.oneTimeFee.toFixed(2)).toString(),
            'cost[merchandise]': (resDat.cost.merchandise === 0 ? "0.00" : resDat.cost.merchandise.toFixed(2)).toString(),
            'cost[final_total]': (resDat.cost.final_total === 0 ? "0.00" : resDat.cost.final_total.toFixed(2)).toString(),
            'proration[firstMonthStartDate]': resDat.proration.firstMonthStartDate,
            'proration[firstMonthEndDate]': resDat.proration.firstMonthEndDate,
            'proration[firstMonthRent]': (resDat.proration.firstMonthRent === 0 ? "0.00" : resDat.proration.firstMonthRent.toFixed(2)).toString(),
            'proration[nextMonthStartDate]': resDat.proration.nextMonthStartDate,
            'proration[nextMonthEndDate]': resDat.proration.nextMonthEndDate,
            'proration[nextMonthRent]': (resDat.proration.nextMonthRent === 0 ? "0.00" : resDat.proration.nextMonthRent.toFixed(2)).toString(),
            'proration[prorationStartDate]': resDat.proration.prorationStartDate,
            'proration[prorationEndDate]': resDat.proration.prorationEndDate,
            'proration[prorationRent]': (resDat.proration.prorationRent === 0 ? "0.00" : resDat.proration.prorationRent.toFixed(2)).toString(),
            'merchandise_list[9][quantity]': calculationData.padlock_quantity,
            'merchandise_list[9][value]': calculationData.padlock_price,
            'idv': (calculationData.protection_plan_quantity > 0 ? 1 : 0),
            //'merchandise_list[27][quantity]':calculationData.protection_plan_quantity,
            //'merchandise_list[27][value]':calculationData.protection_plan_price,
            'prospect[first]': dat.first_name,
            'prospect[last]': dat.last_name,
            'prospect[phone][area]': dat.phone_number.slice(0, 3),
            'prospect[phone][prefix]': dat.phone_number.slice(3, 6),
            'prospect[phone][number]': dat.phone_number.slice(6),
            'prospect[email]': dat.email,
            'prospect[address]': dat.address1,
            'prospect[city]': dat.city,
            'prospect[state]': dat.state,
            'prospect[zip]': dat.f_zip_code,
            [key_name]: key_value, // Add the key_name and key_value pair to the params
            'pid':pid
        };

        try{
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = params[key];
                    form.appendChild(hiddenField);
                }
            }
    
            document.body.appendChild(form);
            form.submit();
            return true;
        }
        catch(e){
            return false
        }
    };


    const updateQuote = async (pid) =>{
        const quoteDat = {
            ...qData,
            delivery_fee: (() => {
                if (valid_zips.includes(address.zip_code)) {
                    return findAmountByZip(address.zip_code);
                } else {
                    return findAmountByZip(zc);
                }
            })(),
            quote_id: sessionStorage.getItem('quote_id'),
            prospect_id: pid,
            delivery_date: deliveryDate,
            delivery_time: deliveryTime,
            storage_location: loca,
            pickup_date: pickupDate,
            total: resDat.cost.final_total,
            customer:{
                ...qData.customer,
                address: address.address1,
                address2: address.address2,
                city: address.city,
                state: address.state,
                zip: address.zip_code
            },
            delivery_instructions:delInst
        }
        try {
            // eslint-disable-next-line
            const response = await AxiosInstance.post("/bigbox-update-quote",quoteDat);
            return true;
        } catch (error) {
            return false;
        }
    }




    // function to handle the submit button click
    // validation is done similar to the screen 1
    const handleContinue = async () => {
        let isValid = true;
        if (valid_zips.includes(sendData.f_zip_code)) {
            setZipError('');
        } else {
            setZipError("Big Box Storage is not available for this zip code");
            isValid = false;
        }
        if (!sendData.first_name.trim()) {
            setFirstNameError('First Name Required');
            isValid = false;
        } else {
            setFirstNameError('');
        }
        if (!sendData.last_name.trim()) {
            setLastNameError('Last Name Required');
            isValid = false;
        } else {
            setLastNameError('');
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(sendData.email)) {
            setEmailError("Invalid email format");
            isValid = false;
        } else {
            setEmailError('');
        }

        const phonePattern = /^(\d{10})$/;
        if (!phonePattern.test(sendData.phone_number)) {
            setPhoneError("Phone number must be 10 digits");
            isValid = false;
        } else {
            setPhoneError('');
        }
        if (!sendData.address1.trim()) {
            setAdd1Error('Address cannot be empty');
            isValid = false;
        } else {
            setAdd1Error('');
        }
        if (!sendData.city.trim()) {
            setCityError('City Required');
            isValid = false;
        } else {
            setCityError('');
        }
        if (!sendData.state.trim()) {
            setStateError('State must be a 2 character abbreviation. Like CA for California');
            isValid = false;
        } else {
            if (!/^[A-Z]{2}$/.test(sendData.state.trim())) {
                setStateError('State must be a 2 character abbreviation. Like CA for California');
                isValid = false;
            } else {
                setStateError('');
            }
        }
        if (isValid) {
                const pid = sessionStorage.getItem('prospect_id')
                // Check if 'pid' is null or an empty string
                if (!pid) {
                    // Display a toast error message if 'pid' is null or empty
                    toast.error('Something went wrong. Please try again.');
                    return; // Exit the function to prevent form submission
                }
                else{
                    setIsSubmitting(true);
                    // sets the mail entered by the user to be used in next screen
                    sessionStorage.setItem('mail', sendData.email);
                    // stores the data entered in this screen before navigating to next page
                    sessionStorage.setItem('s3data', JSON.stringify(s3data));
                    sessionStorage.setItem('delInst', delInst)
                    //createOrder(sendData);
                    try{
                        const updated = await updateQuote(pid);
                        if(updated){
                            const payment_made = await makePayment(sendData,pid);
                            if(payment_made){
                                setIsSubmitting(true);
                            }
                            else{
                                toast.error("payment not made")
                                throw new Error('payment not made');
                            }
                        }else{
                            toast.error('Error: We are unable to update your quote. Please call us to finish your quote.');
                            throw new Error('Unable to update quote');
                        }
                    }
                    catch(err){
                        setIsSubmitting(false);
                    }
                }
            
        }
    };

    // function to display date in the format of 'MMMM d, yyyy' for the user
    const formatDisplayDate = (date) => {
        return format(parseISO(date), 'MMMM d, yyyy');
    };
    // labels and values for the location dropdown
    const location = [
	    { label: 'Big Box - San Diego', value: 'warehouse' },
        { label: 'My Location', value: 'location' },
        
    ];
    // labels and values for the delivery time dropdown
    const time = [
        { label: '8 am - 11 am', value: '800' },
        { label: '11 am - 2 pm', value: '1100' },
        { label: '1 pm - 4 pm', value: '100' },
    ];

    // function to handle the change in the address
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddress((prev) => ({ ...prev, [name]: value }));
    };

    // // function to handle the change in the state
    // const handleStateChange = (event, newValue) => {
    //     setAddress((prev) => ({ ...prev, state: newValue ? newValue.value : "" }));
    // };

    // onload function for google maps
    const onLoad = (autoC) => {
        setAutocomplete(autoC);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
    
            // Initialize variables for city, state, and subpremiseInfo
            let city = '';
            let state = '';
            let subpremise = '';
            let premise = '';
            let sublocality = '';
            let zip = ''
    
            // Loop through the address components and find the desired values
            place.address_components.forEach((component) => {
                const types = component.types;
    
                if (types.includes('locality')) {
                    city = component.long_name;
                }
    
                if (types.includes('administrative_area_level_2') && !city) {
                    city = component.long_name;
                }
    
                if (types.includes('administrative_area_level_1')) {
                    state = component.short_name; // short name property for the 2 letter state code
                }
    
                if (types.includes('subpremise') || types.includes('street_number')) {
                    subpremise = component.long_name;
                }
    
                if (types.includes('premise') || types.includes('route')) {
                    premise = component.long_name;
                }
    
                if (types.includes('sublocality_level_1') || types.includes('sublocality')) {
                    sublocality = component.long_name;
                }
                if (types.includes('postal_code')){
                    zip = component.long_name;
                    setZipError(valid_zips.includes(component.long_name)?"":"Bigbox not available in this location")
                }
            });
    
            // Combine subpremise, premise, and sublocality into a single string
            const subpremiseInfo = [subpremise, premise, sublocality].filter(Boolean).join(', ');
            const cleanedAddress = subpremiseInfo.replace(/,/g, '');
            // Call handleAddressChange for address, city, state, and subpremiseInfo
            handleAddressChange({ target: { name: 'address1', value: cleanedAddress } });
            handleAddressChange({ target: { name: 'city', value: city } });
            handleAddressChange({ target: { name: 'state', value: state } });
            handleAddressChange({ target: { name: 'zip_code', value: zip} });
            // Set focus to the text field after a slight delay
            setTimeout(() => {
                if (textFieldRef.current) {
                textFieldRef.current.focus();
                }
            }, 0); // Setting timeout to 0 ensures the focus happens after the render
    
        } else {
            toast.error('Autocomplete is not loaded yet!');
        }
    };
    


    return (
        <>
         {isSubmitting ? <Loading></Loading> : <>
            <Box py={{ xs: 5, md: 10 }}>
                <Container fixed>
                <Typography mb={{ xs: 3, md: 4 }} color={'primary.main'} variant='h2' order={{ xs: 2, md: 1 }}> {label.headers12}</Typography>
                <QuoteComponent data={quoteData} loading={loading}/>
                <MonthlyQuote data={quoteData} />
                    <Stack direciton={'column'}>
                        <Typography mb={{ xs: 3, md: 4 }} color={'primary.main'} variant='h2' order={{ xs: 2, md: 1 }}> {label.header}</Typography>

                    </Stack>
                    {/* form */}
                    <Grid container spacing={2}>
                        {/* Left section */}
                        <Grid item xs={12} md={12}>
                            {/* Question-5 */}
                            <Box mb={{ xs: 2, sm: 2.5 }} p={2.5} border={'1px solid #DFDFDF'} borderRadius={'8px'}>
                                <Typography mb={{ xs: 1, sm: 2.5 }} component={'p'} color={'text.secondary'} variant='question'>5. {label.headers5}</Typography>
                                <Grid mb={2} container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Delivery Date</Typography>
                                            {/* <StyledTextfield id="email" className='styled-textfield' variant="outlined" placeholder='Enter Phone Number' fullWidth /> */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StyledDatePicker
                                                    className='styled-picker'
                                                    disablePast={true}
                                                    shouldDisableDate={shouldDisableDeliveryDate} // this will get the dates that should be disabled in this date picker
                                                    value={parseISO(deliveryDate)}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            const formattedNewValue = format(newValue, 'yyyy-MM-dd');
                                                            setDeliveryDate(formattedNewValue);
                                                            const newPickupDate = addDays(newValue, 1); // pickup date will be set to one day after the delivery date whenever the delivery date changes
                                                            setPickupDate(format(getValidDate(newPickupDate, inActiveDates), 'yyyy-MM-dd'));
                                                        }
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            inputProps: { value: formatDisplayDate(deliveryDate) } // this input props will be displayed to the user
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Delivery Time</Typography>
                                            <StyledAutocomplete
                                                className='styled-autocomplete'
                                                disablePortal
                                                id="time"
                                                options={time}
                                                value={time.find(option => option.value === deliveryTime)}
                                                onChange={(event, newValue) => { setDeliveryTime(newValue ? newValue.value : '') }}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <StyledInfo>{label.step5bullet1}</StyledInfo>


                            </Box>
                            {/* Question-6 */}
                            <Box mb={{ xs: 2, sm: 2.5 }} p={2.5} border={'1px solid #DFDFDF'} borderRadius={'8px'}>
                                <Typography mb={{ xs: 1, sm: 2.5 }} component={'p'} color={'text.secondary'} variant='question'>6. {label.headers6}</Typography>
                                <Grid mb={2} container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Pick Up Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StyledDatePicker
                                                    className='styled-picker'
                                                    disablePast={true}
                                                    shouldDisableDate={shoulDisablePickupDate}
                                                    value={parseISO(pickupDate)}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setPickupDate(format(newValue, 'yyyy-MM-dd'));
                                                        }
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            inputProps: { value: formatDisplayDate(pickupDate) }
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Grid>

                                </Grid>
                                <StyledInfo>{label.step6bullet1}</StyledInfo>


                            </Box>
                            {/* Question-7 */}
                            <Box mb={{ xs: 2, sm: 2.5 }} p={2.5} border={'1px solid #DFDFDF'} borderRadius={'8px'}>
                                <Typography mb={{ xs: 1, sm: 2.5 }} component={'p'} color={'text.secondary'} variant='question'>7. {label.headers7}</Typography>
                                <Grid mb={2} container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Location</Typography>

                                            <StyledAutocomplete
                                                className='styled-autocomplete'
                                                disablePortal
                                                id="location"
                                                options={location}
                                                value={location.find(option => option.value === loca)}
                                                onChange={(event, newValue) => { setLoca(newValue ? newValue.value : '') }}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid mb={2} container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography mt={1} color={'text.secondary'} fontWeight={600}>The Big Box - San Diego Location</Typography>
                                        <Stack direction={'row'}  flexWrap={'wrap'} alignItems={'center'} gap={1} >
                                        <StyledInfo>{label.step7bullet1}</StyledInfo>
                                        <StyledInfo>{label.step7bullet2}</StyledInfo>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography mt={1} color={'text.secondary'} fontWeight={600}>My Location</Typography>
                                        <Stack direction={'row'}  flexWrap={'wrap'} alignItems={'center'} gap={1} >
                                        <StyledInfo>{label.step7bullet3}</StyledInfo>
                                        <StyledInfo>{label.step7bullet4}</StyledInfo>
                                        </Stack>
                                    </Grid>
                                </Grid>

                            </Box>
                            {/* Question-8 */}
                            <Box mb={{ xs: 2, sm: 2.5 }} p={2.5} border={'1px solid #DFDFDF'} borderRadius={'8px'}>
                                <Typography mb={{ xs: 1, sm: 2.5 }} component={'p'} color={'text.secondary'} variant='question'>8. {label.headers8}</Typography>
                                <Grid mb={2} container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>First Name</Typography>
                                            <StyledTextfield fullWidth placeholder='Enter First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                            {firstNameError && <Typography variant='error'>{firstNameError}</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Last Name</Typography>
                                            <StyledTextfield fullWidth placeholder='Enter Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                            {lastNameError && <Typography variant='error'>{lastNameError}</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>E-mail</Typography>
                                            <StyledTextfield fullWidth placeholder='Enter Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                            {emailError && <Typography variant='error'>{emailError}</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Phone Number</Typography>
                                            <StyledTextfield
                                                fullWidth
                                                placeholder='Enter Phone Number'
                                                value={phoneNumber}
                                                onChange={(event) => setPhoneNumber(event.target.value.replace(/\D/g, '').slice(0,10))}
                                                autoComplete='off'
                                                inputProps={{
                                                    value: formatPhoneNumber(phoneNumber),
                                                    form: {
                                                        autocomplete: 'off',
                                                    },
                                                }}
                                            />
                                            {phoneError && <Typography variant='error'>{phoneError}</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Address 1</Typography>
                                            {isLoaded ? (
                                                <Autocomplete
                                                    onLoad={onLoad}
                                                    onPlaceChanged={onPlaceChanged}
                                                >
                                                    <StyledTextfield
                                                        fullWidth
                                                        placeholder='Enter Address 1'
                                                        name='address1'
                                                        value={address.address1}
                                                        onChange={handleAddressChange}
                                                    />
                                                </Autocomplete>
                                            ) : (<>Loading Maps....</>)}
                                            {add1Error && <Typography variant='error'>{add1Error}</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Box>
                                            <Typography variant='label' component={'label'} color={'text.label'}>Address 2</Typography>
                                            <StyledTextfield
                                                fullWidth
                                                placeholder='Enter Address 2'
                                                name='address2'
                                                value={address.address2}
                                                onChange={handleAddressChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <Typography variant='label' component={'label'} color={'text.label'}>City</Typography>
                                                    <StyledTextfield
                                                        fullWidth
                                                        placeholder='Enter City'
                                                        name='city'
                                                        value={address.city}
                                                        onChange={handleAddressChange}
                                                        inputRef={textFieldRef} // Attach the ref to the text field
                                                    />
                                                    {cityError && <Typography variant='error'>{cityError}</Typography>}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <Typography variant='label' component={'label'} color={'text.label'}>State</Typography>
                                                    <StyledTextfield
                                                        fullWidth
                                                        placeholder='Enter State'
                                                        name='state'
                                                        value={address.state}
                                                        onChange={handleAddressChange}
                                                    />
                                                    {stateError && <Typography variant='error'>{stateError}</Typography>}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <Typography variant='label' component={'label'} color={'text.label'}>Zip code</Typography>
                                                    <StyledTextfield
                                                        fullWidth
                                                        placeholder='Enter Zip code'
                                                        name='zip_code'
                                                        value={address.zip_code}
                                                        onChange={handleAddressChange}
                                                    />
                                                {zipError && <Typography variant='error'>{zipError}</Typography>}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Box>
                            {/* Question-9 */}
                            <Box mb={{ xs: 2, sm: 2.5 }} p={2.5} border={'1px solid #DFDFDF'} borderRadius={'8px'}>
                                <Typography mb={{ xs: 1, sm: 2.5 }} component={'p'} color={'text.secondary'} variant='question'>9. {rtpd.number_of_boxes === "1" ? label.headers9sing : label.headers9plur}</Typography>
                                <StyledTextfield
                                    fullWidth
                                    placeholder="Delivery Instructions"
                                    name="del_inst"
                                    value={delInst}
                                    onChange={(e) => {
                                        setDelInst(e.target.value); // Directly update state
                                        
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Stack mt={4} direction={'row'} spacing={2} justifyContent={'space-between'} useFlexGap flexWrap={'wrap'}>
                        <StyledButton variant='contained' disableElevation onClick={handleBack}>Back</StyledButton>
                        <StyledButton variant='contained' disableElevation onClick={handleContinue} disabled={disabled}>Pay Now</StyledButton>
                    </Stack>
                </Container >
            </Box >
            </>
            }
        </>
    );
}

export default RentOnline
